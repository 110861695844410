
import { PropType } from 'vue'
import { ProductVo } from 'momai'

export default {
  props: {
    curProduct: {
      type: Object as PropType<ProductVo>,
      default: null
    }
  },
  setup: () => {
    return {}
  }
}
